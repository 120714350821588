import { API_URL_REFRESH_TOKEN, DISPLAY_PAGES } from '../constants'
import Cookies from 'js-cookie'
import { api } from '../api'
import { AuthContext, AuthenticationStatus, LoginEvent } from './AuthenticationContext'
import { CurrentUser } from './auth.types'
import history from '../history'

type LoginResponse = {
  userId: string
  userEmail: string
  firstName: string
  lastName: string
  accessToken: string
  accessTokenExpMs: number
  sidebarItems: string[]
  developerOptions: boolean
  temperatureInC: number
  permissions: string[]
}

export const UNAUTH_ALLOWED_PATHS = ['/email-unsubscribed', '/set-new-password', '/initial-password', '/login', '/set-new-pin']

export const userLogout = () => {
  Cookies.remove(LoginEvent.loginCookie)
  localStorage.removeItem(LoginEvent.logout)
  const currentPath = history.location.pathname
  if (!UNAUTH_ALLOWED_PATHS.includes(currentPath)) {
    history.push('/login')
  }
}

export const displayPagesChanged = (currSideBar: string[], respSideBar: string[]) => {
  if (currSideBar.length != respSideBar.length) return true
  const respSet = new Set(respSideBar)
  const respEvery: boolean = currSideBar.every((page) => respSet.has(page))
  if (!respEvery) return true
  const currSet = new Set(currSideBar)
  const currEvery: boolean = respSideBar.every((page) => currSet.has(page))
  if (!currEvery) return true
  return false
}

export const checkTokenBeforeAppLaunch = async () => {
  try {
    if (Cookies.get(LoginEvent.loginCookie)) {
      const initializerLoginResponse = await api
        .noAuth()
        .url(`${API_URL_REFRESH_TOKEN}`)
        .options({ credentials: 'include', mode: 'cors', cookie: Cookies.get(LoginEvent.loginCookie) })
        .post()
        .json()
      if (initializerLoginResponse.accessToken) {
        const {
          userId,
          userEmail,
          firstName,
          lastName,
          accessToken,
          accessTokenExpMs,
          sidebarItems,
          developerOptions,
          temperatureInC,
          permissions,
        } = initializerLoginResponse
        api.setToken(accessToken)
        Cookies.set(LoginEvent.loginCookie, AuthenticationStatus.loggedIn)
        localStorage.setItem(LoginEvent.logout, AuthenticationStatus.loggedOut + Math.random())
        const initialAuthState = {
          authState: {
            currentUser: { userId, userEmail, firstName, lastName } as CurrentUser,
            accessToken,
            currentContext: AuthContext.loggedIn,
            tokenExpiration: accessTokenExpMs,
            sidebarItems,
            displayPages: developerOptions ? [...sidebarItems, DISPLAY_PAGES.ITEM_DEVELOPER_OPTIONS] : [...sidebarItems],
            developerOptions,
            temperatureInC,
            initialized: true,
            permissions: permissions,
          },
          success: true,
        }
        return { ...initialAuthState }
      } else {
        Cookies.remove(LoginEvent.loginCookie)
        localStorage.removeItem(LoginEvent.logout)
        const currentPath = history.location.pathname
        if (!UNAUTH_ALLOWED_PATHS.includes(currentPath)) {
          history.push('/login')
        }
      }
    }
  } catch (e) {
    console.warn('Warning, authentication refresh caused an error.')
  }
  return { authState: undefined, success: false }
}
