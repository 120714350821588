import Cookies from 'js-cookie'
import React from 'react'
import { api } from '../api'
import { API_URL_REFRESH_TOKEN, ActionPermissions, AppActionPermissions, DISPLAY_PAGES } from '../constants'
import { logIn } from '../../layout/login/api/login.api'
import _ from 'lodash'
import history from '../history'
import { valueof } from '../../webapp-lib/pathspot-react/'
import { CurrentUser } from './auth.types'
import { UNAUTH_ALLOWED_PATHS } from './auth'

const isAnyFieldDefinedOrTrue = <T extends Record<string, boolean | undefined>>(obj: T): boolean =>
  Object.values(obj).some((value) => value === true)

export const LoginEvent = {
  login: `event-login-customer-facing-${process.env.NODE_ENV}`,
  logout: `event-logout-customer-facing-${process.env.NODE_ENV}`,
  loginCookie: `event-login-cookie-customer-facing-${process.env.NODE_ENV}`,
  loginToken: `event-login-token-customer-facing-${process.env.NODE_ENV}`,
} as const

type LoginEvent = keyof typeof LoginEvent

const defaultTokenExpiration = 900000
export enum LoginResponse {
  success = 'success',
  fail = 'fail',
  error = 'error',
  unknown = 'unknown',
}

export const AuthenticationStatus = {
  loggedIn: `loginstatus:loggedIn:${process.env.NODE_ENV}`,
  loggedOut: `loginstatus:loggedOut:${process.env.NODE_ENV}`,
  unknown: `loginstatus:unknown:${process.env.NODE_ENV}`,
} as const

export type AuthenticationStatusType = valueof<typeof AuthenticationStatus>
export enum AuthContext {
  loggedIn = 'loggedIn',
  notLoggedIn = 'notLoggedIn',
  unknown = 'unknown',
}

const initialPermissionSet = {
  assign_tasks: false,
  assign_labels: false,

  assign_lg_tasks: false,
}

export type AuthState = {
  currentContext: AuthContext
  currentUser: CurrentUser
  sidebarItems: Array<any>
  accessToken: string
  tokenExpiration: number
  developerOptions: any
  displayPages: DISPLAY_PAGES[]
  permissions: AppActionPermissions
  isAdmin: boolean
  temperatureInC: boolean
  initialized: boolean
}
export const defaultCurrentUser: CurrentUser = {
  userId: -1,
  userEmail: '',
  firstName: '',
  lastName: '',
}
export const defaultAuthState: AuthState = {
  currentContext: AuthContext.unknown,
  currentUser: { ...defaultCurrentUser },
  sidebarItems: [],
  accessToken: '',
  tokenExpiration: -1,
  developerOptions: null,
  displayPages: [],
  permissions: initialPermissionSet,
  isAdmin: false,
  initialized: false,
  temperatureInC: false,
}
export type AuthenticationProviderState = {
  authState: AuthState
  currentUser: CurrentUser
  isPathspotUser: boolean
  setAuthState: (authState: AuthState) => void
  userLogin: (values: any) => Promise<LoginResponse>
  userLogout: () => void
  silentlyRefreshToken: () => Promise<LoginResponse>
  checkPermissions: (permission: DISPLAY_PAGES) => boolean
  checkActionPermission: (permission: ActionPermissions) => boolean
}
const initialState: AuthenticationProviderState = {
  authState: { ...defaultAuthState },
  currentUser: { ...defaultCurrentUser },
  isPathspotUser: false,
  setAuthState: () => {},
  userLogin: async () => LoginResponse.unknown,
  userLogout: () => {},
  silentlyRefreshToken: async () => LoginResponse.unknown,
  checkPermissions: () => true,
  checkActionPermission: () => false,
}
const AuthenticationContext = React.createContext(initialState)
type AuthStateAndStatus = {
  authState: AuthState
  success: boolean
}
type AuthenticationContextProps = {
  children: React.ReactNode
  initialAuthState: AuthStateAndStatus
}

const isCurrentUserPathspotUser = (email: string) => {
  return email.includes('@pathspot.com') || email.includes('@pathspottech.com')
}

function AuthenticationProvider({ children, initialAuthState }: AuthenticationContextProps) {
  const submitting = React.useRef<boolean | null>(null)
  const initialized = React.useRef<boolean>(false)
  const [authState, _setAuthState] = React.useState<AuthState>({ ...initialState.authState })

  const setAuthState = (newAuthState: AuthState) => {
    _setAuthState({ ...newAuthState })
  }

  const updateAuthState = (updateData: any) => {
    _setAuthState({ ...authState, ...updateData })
  }

  const notLoggedInRedirect = () => {
    const currentPath = window.location.pathname

    if (!UNAUTH_ALLOWED_PATHS.includes(currentPath)) {
      history.push('/login')
    }
  }

  const setAuthStateFromLogout = () => {
    if (authState.currentContext !== AuthContext.notLoggedIn) {
      updateAuthState({
        ...defaultAuthState,
        currentContext: AuthContext.notLoggedIn,
      } as AuthState)
    }
  }

  const userLogout = () => {
    Cookies.remove(LoginEvent.loginCookie)
    localStorage.removeItem(LoginEvent.logout)
    setAuthStateFromLogout()
    notLoggedInRedirect()
  }

  const updatedAuthStateValidResponse = (logInResponse: any) => {
    const {
      userId,
      userEmail,
      firstName,
      lastName,
      accessToken,
      accessTokenExpMs,
      sidebarItems,
      developerOptions,
      temperatureInC,
      permissions,
    } = logInResponse

    updateAuthState({
      currentUser: { userId, userEmail, firstName, lastName } as CurrentUser,
      accessToken,
      currentContext: AuthContext.loggedIn,
      tokenExpiration: accessTokenExpMs || defaultTokenExpiration,
      sidebarItems,
      permissions,
      displayPages: developerOptions ? [...sidebarItems, DISPLAY_PAGES.ITEM_DEVELOPER_OPTIONS] : [...sidebarItems],
      developerOptions,
      temperatureInC,
      initialized: true,
    } as AuthState)
  }

  const parseLoginResponse = (loginResponse: any) => {
    try {
      if (loginResponse && loginResponse.accessToken) {
        const { accessToken, accessTokenExpMs } = loginResponse
        api.setToken(accessToken)
        Cookies.set(LoginEvent.loginCookie, AuthenticationStatus.loggedIn)
        localStorage.setItem(LoginEvent.logout, AuthenticationStatus.loggedOut + Math.random())

        setTimeout(() => {
          silentlyRefreshToken()
        }, Math.floor(accessTokenExpMs / 2))

        updatedAuthStateValidResponse(loginResponse)
        return LoginResponse.success
      }
      return LoginResponse.fail
    } catch (e: unknown) {
      console.error('Error parsing login response: ', e)
      return LoginResponse.error
    }
  }

  const silentlyRefreshToken = async () => {
    let loginResult: any = null

    submitting.current = true
    if (Cookies.get(LoginEvent.loginCookie) === AuthenticationStatus.loggedIn) {
      try {
        const silentLogInResponse = await api
          .noAuth()
          .url(`${API_URL_REFRESH_TOKEN}`)
          .options({ credentials: 'include', mode: 'cors' })
          .post()
          .json()

        loginResult = parseLoginResponse(silentLogInResponse)
        submitting.current = false
      } catch (err) {
        submitting.current = false
        console.warn('!!!!! Attempt to refresh token caused an error !!!!!: ', _.cloneDeep({ loginResult, ...authState, err }))
      }
      if (loginResult === LoginResponse.fail) {
        setAuthStateFromLogout()
      } else if (loginResult !== null) {
        console.error('Access token refresh attempt did not return a reliable result. Please contact PathSpot support.')
      }
    } else {
      notLoggedInRedirect()
    }

    return loginResult
  }

  const userLogin = async (values: any) => {
    const data = { email: values.email.toLowerCase(), password: values.password }
    // send log in request to the API
    const logInResponse = await logIn(data)

    if (parseLoginResponse(logInResponse) === LoginResponse.success) {
      updatedAuthStateValidResponse(logInResponse)
      return LoginResponse.success
    } else {
      return LoginResponse.fail
    }
  }

  const checkPermissions = (page: DISPLAY_PAGES) => {
    return authState.displayPages.includes(page)
  }

  // returns boolean if permission exists and its corresponding value
  const checkActionPermission = (permission: ActionPermissions): boolean => {
    return isAnyFieldDefinedOrTrue(authState.permissions) && Object.hasOwn(authState.permissions, permission)
      ? authState.permissions[permission] ?? false
      : false
  }

  if (initialAuthState.success && initialized.current === false) {
    updatedAuthStateValidResponse({ ...initialAuthState.authState, ...initialAuthState.authState.currentUser })
    initialized.current = true
    setTimeout(() => {
      silentlyRefreshToken()
    }, Math.floor(initialAuthState.authState.tokenExpiration / 2))
  }

  return (
    <AuthenticationContext.Provider
      value={{
        authState,
        currentUser: { ...authState.currentUser },
        isPathspotUser: isCurrentUserPathspotUser(authState.currentUser.userEmail),
        setAuthState,
        userLogin,
        userLogout,
        silentlyRefreshToken,
        checkPermissions,
        checkActionPermission,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export { AuthenticationProvider, AuthenticationContext }
